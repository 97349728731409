.App {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #fbe7cd;

  > .contentBox {
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    background-color: #fbe7cd;
    .english {
      position: relative;
      .Bg {
        width: 100%;
      }
      .BgBtn {
        display: block;
        position: absolute;
        top: 39%;
        left: 5%;
        width: 90%;
      }
    }
  }
  .allTop {
    width: 100%;
    height: 80px;
    background-color: rgba($color: #000000, $alpha: 0.3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 99999;
    position: absolute;
    top: 0;
  }
  .top {
    height: 80px;
    z-index: 99999;
    min-width: 1248px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 20px;
    font-weight: 600;
    z-index: 999999;
    color: #ffffff;

    .left {
      display: flex;
      align-items: center;
      gap: 20px;
      img {
        width: 42px;
      }
    }
    .center {
      display: flex;
      gap: 50px;
      justify-content: flex-start;
      margin-left: -200px;
      .item {
        cursor: pointer;
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      border-radius: 40px;
      padding: 8px 20px;
      border: 1px solid #fff;
      img {
        margin-top: 2px;
        margin-right: 5px;
      }
    }
  }
  .Bg1 {
    width: 100%;
    position: relative;

    .bg {
      width: 100%;
      height: 100%;
    }
    .Img12 {
      position: absolute;
      bottom: 16%;
      right: 10%;
      width: 30%;
    }
  }
  .Bg2 {
    width: 100%; /* 或者你需要的固定宽度 */
    height: 35vw;
    background-color: #e9f6ff;

    .center {
      width: 90%;
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4%;
      img {
        width: 30%;
      }
      .right {
        width: 30%;
        img {
          width: 20%;
          margin-bottom: 5%;
        }
        .title {
          font-size: 2.5vw;
          font-weight: bold;
          margin-bottom: 8%;
        }
        .desc {
          width: 80%;
          line-height: 2vw;
          font-size: 1.2vw;
        }
      }
    }
  }
  .Bg3 {
    width: 100%; /* 或者你需要的固定宽度 */
    height: 35vw;
    background-color: #ffffff;

    .center {
      width: 90%;
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0%;
      img {
        width: 35%;
      }
      .right {
        width: 30%;
        img {
          width: 20%;
          margin-bottom: 8%;
        }
        .title {
          font-size: 2.5vw;
          font-weight: bold;
          margin-bottom: 8%;
        }
        .desc {
          width: 80%;
          line-height: 20px;
          font-size: 1.2vw;
        }
      }
    }
  }
  .about {
    width: 100%;
    height: 40vw;
    .center {
      width: 90%;
      margin: auto;
      text-align: center;
      padding-top: 4%;
      .top {
        text-align: center;
        position: relative;
        height: 10%;
        margin-bottom: 4%;
        img {
          width: 18%;
          margin: auto;
        }
        .text {
          position: absolute;
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #000000;
          font-size: 2.8vw;
          font-weight: 700;
        }
      }
      .list {
        width: 68%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2%;
        .item {
          width: 28%;
          height: 24vw;
          background-color: #f4faff;
          border-radius: 10px;
          overflow: hidden;
          img {
            width: 100%;
          }
          .title {
            font-size: 1vw;
            font-weight: bold;
            margin: 0.3vw 0 0.5vw;
          }
          .desc {
            font-size: 12px;
            padding: 0 3%;
          }
        }
      }
    }
  }

  .bottom {
    height: 70px;
    background-color: #000000;
    display: flex;
    padding: 0 200px;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2vw;
    color: #fff;
    .bottom_right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      img {
        width: 30%;
        margin-bottom: -3%;
        margin-right: -3%;
      }
    }
  }
  .bottom2 {
    height: 40px;
    margin-bottom: 30px;
    background-color: #e5e8ef;
    text-align: center;
  }
}
